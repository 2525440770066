import React, { useState, useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Link } from "gatsby"
import styled from "styled-components"
import TextField from "@mui/material/TextField"
import "./checkout.css"
import { BiRefresh } from "@react-icons/all-files/bi/BiRefresh"
import successGif from "../../images/success.gif"

import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { StaticImage } from "gatsby-plugin-image"

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#87bbfd",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
}

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement
      style={{ width: "100%" }}
      options={CARD_OPTIONS}
      onChange={onChange}
    />
  </div>
)

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
)

const SubmitButton = ({ processing, error, children, disabled }) => (
  <div>
    <button
      className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
      type="submit"
      disabled={processing || disabled}
    >
      {processing ? "Processing..." : children}
    </button>
  </div>
)

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <h2 className="error_h2">{children}</h2>
  </div>
)

const ResetButton = ({ onClick }) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <BiRefresh className="reset_icon" />
  </button>
)

const CheckoutForm = (props) => {
  const { total } = props
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)
  const [cardComplete, setCardComplete] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(null)

  //userContext

  //userContext
  const [stripeToken, setStripeToken] = React.useState(null)
  const [success, setSuccess] = React.useState(null)
  const [showPayment, setShowPayment] = React.useState("")
  const [stripeTotal, setStripeTotal] = React.useState()

  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
    title_of_the_paper: "",
    name_of_the_authors: "",
  })

  useEffect(() => {
    const loadToken = async () => {
      const response = await fetch(
        `https://anastrapiatlas.herokuapp.com/conference-user-details/payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            total: total,
          }),
        }
      )

      const data = await response.json()
      setStripeToken(data.client_secret)
      setStripeTotal(data.amount / 100)
    }
    loadToken()
  }, [total])

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (error) {
      elements.getElement("card").focus()
      return
    }

    if (cardComplete) {
      setProcessing(true)
    }

    const result = await stripe.confirmCardPayment(stripeToken, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: billingDetails.name,
          email: billingDetails.email,
          phone: billingDetails.phone,
          address: {
            line1: "510 Townsend St",
            postal_code: "98140",
            city: "San Francisco",
            state: "CA",
            country: "US",
          },
        },
      },
    })
    const data = {
      payment_intent: result.paymentIntent,
      name: billingDetails.name,
      email: billingDetails.email,
      phone: billingDetails.phone,
      title_of_the_paper: billingDetails.title_of_the_paper,
      name_of_the_authors: billingDetails.name_of_the_authors,
    }

    fetch(`${process.env.STRAPI_API_URL}/conference-user-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

    setProcessing(false)

    if (result.error) {
      setError(result.error)
    } else {
      setPaymentMethod(result.paymentIntent)
      setSuccess(true)
      window.scrollTo({ behavior: "smooth", top: "0px" })
    }
  }

  const reset = () => {
    setError(null)
    setProcessing(false)
    setPaymentMethod(null)
    setBillingDetails({
      email: "",
      phone: "",
      name: "",
    })
  }
  const handlePayment = (value) => {
    setShowPayment(value)
  }

  return paymentMethod ? (
    <div className="Result">
      <div className="image">
        <img
          className="success_gif"
          src={successGif}
          alt="payment successfull"
        />
      </div>
      <div className="ResultTitle" role="alert">
        <h1 className="payment_sucess">Payment Successfull</h1>
      </div>
      <div className="ResultMessage">
        <h2 className="result_message">
          You're payment is confirmed & you'll recieve a message shortly.
        </h2>
      </div>
    </div>
  ) : (
    <Wrapper>
      <div className="form_wrapper">
        <form className="Form" onSubmit={handleSubmit}>
          <span style={{ color: "var(--charcoal)", fontStyle: "italic" }}>
            (use 'comma' for multiple authors)
          </span>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name_of_the_authors"
            label="Name of the Author/s"
            name="name_of_the_authors"
            placeholder="use 'comma' for mutltipe authors"
            value={billingDetails.name_of_the_authors}
            multiline
            InputLabelProps={{
              style: {
                color: "#151515",
                opacity: "0.8",
                fontFamily: "var(--family)",
                fontWeight: "500",
              },
            }}
            onChange={(e) =>
              setBillingDetails({
                ...billingDetails,
                name_of_the_authors: e.target.value,
              })
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="title_of_the_paper"
            label="Title of the paper"
            name="title_of_the_paper"
            value={billingDetails.title_of_the_paper}
            InputLabelProps={{
              style: {
                color: "#151515",
                opacity: "0.8",
                fontFamily: "var(--family)",
                fontWeight: "500",
              },
            }}
            onChange={(e) =>
              setBillingDetails({
                ...billingDetails,
                title_of_the_paper: e.target.value,
              })
            }
          />
          <h2 className="payment_info">payment info</h2>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Full Name"
            name="name"
            value={billingDetails.name}
            InputLabelProps={{
              style: {
                color: "#151515",
                opacity: "0.8",
                fontFamily: "var(--family)",
                fontWeight: "500",
              },
            }}
            onChange={(e) =>
              setBillingDetails({
                ...billingDetails,
                name: e.target.value,
              })
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={billingDetails.email}
            InputLabelProps={{
              style: {
                color: "#151515",
                opacity: "0.8",
                fontFamily: "var(--family)",
                fontWeight: "500",
              },
            }}
            onChange={(e) =>
              setBillingDetails({
                ...billingDetails,
                email: e.target.value,
              })
            }
          />
          <fieldset className="FormGroup" style={{ padding: "20px 10px" }}>
            <CardElement
              style={{ width: "100%" }}
              options={CARD_OPTIONS}
              onChange={(e) => {
                setError(e.error)
                setCardComplete(e.complete)
              }}
            />
          </fieldset>

          {error && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ErrorMessage>{error.message}</ErrorMessage>{" "}
              <ResetButton onClick={reset} />
            </div>
          )}
          {stripeTotal ? (
            <SubmitButton
              processing={processing}
              error={error}
              disabled={!stripe}
            >
              Pay{` `}
              {stripeTotal
                ? stripeTotal.toLocaleString("en-US", {
                    style: "currency",
                    currency: "usd",
                  })
                : `loading..`}
            </SubmitButton>
          ) : null}
        </form>
      </div>
    </Wrapper>
  )
}

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51J3eEiSD2J7P00eXE40TL1htRa9CTg8NORJeHY7MmD9DozojtRfviXQiIXdRbwJ2aRPY0n9H1cB77V4lTfWcksHT006TLv0blx"
)

const App = (props) => {
  const { total } = props
  return (
    <div className="AppWrapper">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm total={total} />
      </Elements>
    </div>
  )
}

export default App

const Wrapper = styled.div`
  width: 100%;

  .form_wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    @media (max-width: 479px) {
      padding: 10px 0;
    }

    .payment_info {
      font-weight: 600;
      font-size: 20px;

      color: #002f54;
    }
  }
  input {
    color: #000 !important;
  }
  .InputContainer .InputElement {
    color: #000 !important;
  }
  .reset_icon {
    font-size: 22px;
    color: var(--thirdColor);
  }
  .upi_button_wrapper {
    display: flex;
    min-height: 200px;
    justify-content: center;
    align-items: center;
    width: 100%;
    .razorpay_link {
      min-width: 150px;
      padding: 15px 25px;
      background: var(--thirdColor);
      color: var(--secondaryColor);
      font-size: 1rem;
      font-weight: 500;
      border-radius: 50px;
      cursor: pointer;
    }
  }
`
