import React from "react"
import styled from "styled-components"
import Checkout from "../components/Checkout/CheckOutForm"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

function Conference({ data }) {
  const { price, title, description } = data.strapiConferences
  let total = price * 100
  let mainTotal = price
  return (
    <Container>
      <Wrapper>
        <div className="ici4_left_section_wrapper">
          <div className="heading">
            <h2>{title}</h2>
          </div>
          <div className="amount">
            <h4>
              {mainTotal.toLocaleString("en-US", {
                style: "currency",
                currency: "usd",
              })}
            </h4>
          </div>
          <div className="image">
            <GatsbyImage
              image={
                data.strapiConferences.image[0].localFile.childImageSharp
                  .gatsbyImageData
              }
              alt={title}
            />
          </div>
          <div className="description">
            <ReactMarkdown>{description}</ReactMarkdown>
          </div>
        </div>
        <div className="ici4_right_section_wrapper">
          <div className="fill_the_blanks">
            <h3>Fill in the following Details</h3>
          </div>
          <div className="checkout">
            <Checkout total={total} />
          </div>
        </div>
      </Wrapper>
    </Container>
  )
}

export default Conference

export const query = graphql`
  query Conference($slug: String) {
    strapiConferences(slug: { eq: $slug }) {
      slug
      description
      price
      strapiId
      title
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
          }
        }
      }
    }
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  width: 100%;
  height: auto;
  margin-top: 100px;
  margin-bottom: 120px;
`
const Wrapper = styled.div`
  grid-area: auto/2/auto/11;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  height: auto;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 991px) {
    place-items: center;
  }

  .ici4_left_section_wrapper {
    grid-area: auto/1/auto/2;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    @media (max-width: 991px) {
      grid-area: auto/1/auto/3;
      width: 80%;
      padding-right: 0;
    }
    @media (max-width: 767px) {
      width: 100%;
      padding-right: 0;
    }
    .heading {
      h2 {
        font-size: 2rem;
        font-weight: 600;
        color: #000 !important;
        margin-bottom: 0;
        @media (max-width: 1250px) {
          font-size: 1.5rem;
        }
      }
    }
    .amount {
      h4 {
        font-size: 1.5rem;
        font-weight: 500;
        color: #000;
      }
    }
    .image {
      display: block;
      width: 400px;
      height: 400px;
      @media (max-width: 767px) {
        width: 100%;
      }
      @media (max-width: 479px) {
          height: 300px !important;
        }
      img {
        width: 400px;
        height: 400px;
        border-radius: 6px;
        @media (max-width: 767px) {
          width: 100%;
        }
        @media (max-width: 479px) {
          height: 300px !important;
        }
      }
    }
    .description {
      display: flex;
      width: 80%;
      @media (max-width: 991px) {
        width: 100%;
      }
      p {
        font-size: 1.3rem;
        font-weight: 400;
        @media (max-width: 1250px) {
          font-size: 1rem;
        }
      }
    }
  }
  .ici4_right_section_wrapper {
    grid-area: auto/2/auto/3;
    width: 100%;
    padding-left: 10px;
    @media (max-width: 991px) {
      grid-area: auto/1/auto/3;
      width: 80%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    .fill_the_blanks {
      display: flex;
      border-bottom: 1px solid #000;
      h3 {
        font-size: 1.1.rem;
        font-weight: 400;
        color: var(--secondaryColor);
      }
    }
    .checkout {
      ::-webkit-input-placeholder {
        color: #000 !important;
      }
      :-ms-input-placeholder {
        color: #000 !important;
      }
      :-moz-placeholder {
        color: #000 !important;
      }
      ::-moz-placeholder {
        color: #000 !important;
      }
    }
  }
`
